import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import Container from '../Container'
import { awards } from './awards'
import { useRecognition } from './useRecongition'

export default () => {
  const images = useRecognition()
  return (
    <>
      <TimelineSection id='recognition'>
        <Recognition>
          <Container>
            <SectionHeading>Awards</SectionHeading>
            <H3>Internationally recognised and awarded</H3>
            <Grid>
              <Copy>
                daisee is a global leader in AI-driven voice analytics and speech-to-text transcription. Our technology
                has been recognised and awarded on both local and international fronts, and continues to serve as the
                industry benchmark.
              </Copy>
            </Grid>
          </Container>
          <Container>
            <AwardGrid>
              {awards.map(award => {
                return award.href.startsWith('/') ? (
                  <GridItem key={award.id} gridArea={award.id} alignSelf={award.alignSelf}>
                    <Link to={award.href}>
                      <StyledImg fluid={images[award.id].childImageSharp.fluid} alt={award.label} />
                    </Link>
                  </GridItem>
                ) : (
                  <GridItem key={award.id} gridArea={award.id} alignSelf={award.alignSelf}>
                    <a href={award.href}>
                      <StyledImg fluid={images[award.id].childImageSharp.fluid} alt={award.label} />
                    </a>
                  </GridItem>
                )
              })}
            </AwardGrid>
          </Container>
        </Recognition>
      </TimelineSection>
    </>
  )
}

const TimelineSection = styled.section``

interface ImgProps {
  fluid: FluidObject
}

const AwardGrid = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'regtech anziif hkFintechWeek21 .'
    'gartner gartner gartner gartner'
    'australiaLanding aseantech fintech tech23'
    'techRocketship westpac fintech100 dxc';
`

interface GridColumI {
  gridArea: string
  alignSelf?: 'center' | 'start' | 'end'
}

const GridItem = styled.figure<GridColumI>`
  grid-area: ${({ gridArea }) => gridArea};
  align-self: ${({ alignSelf }) => alignSelf};
`

const StyledImg = styled(Img)<ImgProps>`
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadow.large};
  }
`

const SectionHeading = styled.h2`
  font-size: 4rem;
  font-weight: 300;
  letter-spacing: -2px;
  color: ${({ theme }) => theme.colors.grey400};
  padding: 0 0 6rem;

  @media all and (max-width: 48em) {
    font-size: 3rem;
    padding: 0 0 3rem;
  }

  @media all and (max-width: 28.125em) {
    font-size: 2.5rem;
  }
`

const Recognition = styled.article`
  padding: 3rem 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 0 6rem;
  gap: 1.5rem;
  @media all and (max-width: 39.375em) {
    grid-template-columns: 1fr;
  }
`

const Copy = styled.p`
  color: ${({ theme }) => theme.colors.grey700};
  line-height: 1.7;
  font-weight: 500;
`

const H3 = styled.h3`
  padding: 0rem 0 5rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -1px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -1.5rem;
    width: 4rem;
    height: 4px;
    background: ${({ theme }) => theme.colors.purple};
  }

  @media all and (max-width: 48em) {
    line-height: 1.3;
  }

  @media all and (max-width: 39.375em) {
    font-size: 1.5rem;
  }
`
