import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { pressReleases } from '../constants';
import { ICompany } from '../typescript/interfaces';
import { orderImages } from '../utils/orderImages';

interface IPressReleaseEdges {
  edges: {
    node: {
      name: string;
      childImageSharp: {
        fluid: FluidObject;
      };
      publicURL: string;
    };
  }[];
}

interface IPressReleaseData {
  allFile: IPressReleaseEdges;
}

export const usePressReleases = (): ICompany[] | undefined => {
  const data = useStaticQuery<IPressReleaseData>(graphql`
    query PressReleases {
      allFile(filter: { relativeDirectory: { eq: "press" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            publicURL
          }
        }
      }
    }
  `);

  return orderImages({ list: pressReleases, data }) as ICompany[];
};
