import { ICompany } from '../typescript/interfaces'

export const customers: ICompany[] = [
    { id: 'myob', url: 'https://www.myob.com/', imgSrc: '/customers/myob.svg', alt: 'MYOB logo' },
    { id: 'employsure', url: 'https://employsure.com.au/', imgSrc: '/customers/employsure.svg', alt: 'Employsure logo' },
    { id: 'petSure', url: 'https://petsure.com.au/', imgSrc: '/customers/petsure.png', alt: 'Petsure logo' },
    { id: 'bizCover', url: 'https://www.bizcover.com.au/', imgSrc: '/customers/bizCover.svg', alt: 'BizCover logo' },
    { id: 'tmb', url: 'https://www.tmbank.com.au/', imgSrc: '/customers/teachers_mutual_bank_logo.png', alt: 'Teachers Mutual Bank logo' },
    { id: 'nobleOak', url: 'https://www.nobleoak.com.au/', imgSrc: '/customers/nobleOak.svg', alt: 'NobleOak logo' },
    { id: 'fidelitylife', url: 'https://www.fidelitylife.co.nz/', imgSrc: '/customers/fidelitylife.png', alt: 'Fidelitylife logo' },
    { id: 'ampol', url: 'https://www.ampol.com.au/', imgSrc: '/customers/ampol.png', alt: 'AMPOL logo' },
]
