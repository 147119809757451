import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { AwardKeys } from './awards'

type ImageData = Record<AwardKeys, { childImageSharp: { fluid: FluidObject } }>

export const useRecognition = () => {
  const images = useStaticQuery<ImageData>(graphql`
    query Recognition {
      gartner: file(relativePath: { eq: "recognition/gartner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aseantech: file(relativePath: { eq: "recognition/asean.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dxc: file(relativePath: { eq: "recognition/dxc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fintech100: file(relativePath: { eq: "recognition/fintech100.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fintech: file(relativePath: { eq: "recognition/fintech.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tech23: file(relativePath: { eq: "recognition/tech23.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      techRocketship: file(relativePath: { eq: "recognition/rocketship.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      westpac: file(relativePath: { eq: "recognition/westpac.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      australiaLanding: file(relativePath: { eq: "recognition/auslanding.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      anziif: file(relativePath: { eq: "recognition/anziif.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hkFintechWeek21: file(relativePath: { eq: "recognition/hkFintechWeek21.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      regtech: file(relativePath: { eq: "recognition/regtech.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return images
}
