import React from 'react';
import styled from 'styled-components';
import Container from '../Container';
import Marquee from '../Marquee';
import { usePressReleases } from '../../hooks/usePressReleases';
import { chunk } from 'lodash';

export default () => {
  const pressReleases = usePressReleases();
  const chunkedPress = pressReleases?.length ? chunk(pressReleases, Math.round(pressReleases.length / 2)) : [];

  return (
    <PressReleases>
      <Container>
        <H3>Featured & Reported in</H3>
        <Logos>
          <Marquee transparent list={chunkedPress[0]} scrollDirection="left" />
          <Marquee transparent list={chunkedPress[1]} scrollDirection="right" />
        </Logos>
      </Container>
    </PressReleases>
  );
};

const PressReleases = styled.section`
  background: ${({ theme }) => theme.colors.grey200};
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
`;

const Logos = styled.div`
  padding: 4rem 0 6rem;
  grid-auto-flow: row;
  grid-template-rows: auto;
  gap: 5rem;
  justify-items: center;
  align-items: center;
`;

const H3 = styled.h3`
  padding-top: 3rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: center;
`;
