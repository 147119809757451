import Img, { FluidObject } from 'gatsby-image'
import React from 'react'

interface Props {
  imgSrc: string | FluidObject
  alt?: string
}

export const fluidOrSvgImg = ({ imgSrc, alt }: Props) =>
  typeof imgSrc === 'string' ? (
    <img src={imgSrc} width={100} alt={alt} />
  ) : (
    <Img fluid={imgSrc as FluidObject} alt={alt} />
  )
