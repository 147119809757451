import React, { useState, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import styled from 'styled-components'
import Container from '../Container'
import { SectionHeading } from '../OurPeople'
import Rule from '../Rule'

const values = [
  {
    value: 'Personally Principled',
    description:
      'We question the impact of our actions and ask if we would be comfortable taking personal responsibility. We go beyond self interest and believe in ethical restraint.',
    id: 'PP'
  },
  {
    value: 'Individually Awesome',
    description:
      'Our people have dynamic capabilities that can adapt quickly. We are curious, thoughtful and generous.',
    id: 'IA'
  },
  {
    value: 'Collectively Brilliant',
    description:
      'We play for the name on the front of the jersey, not the one on the back. Together we exceed the sum of our awesome parts.',
    id: 'CB'
  },
  {
    value: 'Future Focused',
    description:
      'We focus on the important not the urgent, to relentlessly pursue our agreed goals. We are creative, evolving rapidly through iteration.',
    id: 'FF'
  },
  {
    value: 'Invested',
    description: 'We are empowered and action oriented. We are decisive and focus on mission critical tasks.',
    id: 'I'
  }
]

interface Icon {
  node: {
    id: string
    name: string
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const OurValues: React.FC = () => {
  const [hoveredElement, setHoveredElement] = useState<undefined | number>(undefined)
  const ref = useRef(null)

  const data = useStaticQuery(graphql`
    query Values {
      allFile(filter: { relativeDirectory: { eq: "values" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const handleHover = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget as HTMLElement
    if (target instanceof HTMLElement && target.dataset.id !== undefined) {
      setHoveredElement(+target.dataset.id)
    }
  }

  return (
    <StyledOurValues>
      <Container>
        <SectionHeading id='values'>Our values</SectionHeading>
        <Rule />
        <Wrapper>
          {values.map((el, index) => {
            return (
              <React.Fragment key={el.value}>
                <ValueWrapper>
                  {data.allFile.edges.map((value: Icon) => {
                    if (value.node.name === el.id) {
                      return (
                        <Icon key={index}>
                          <Img key={value.node.name} fluid={value.node.childImageSharp.fluid} alt={el.value} />
                        </Icon>
                      )
                    }
                    return null
                  })}
                  <Value>
                    <Heading data-id={index} className={hoveredElement === index ? 'active' : ''}>
                      {el.value}
                    </Heading>
                  </Value>
                </ValueWrapper>
                <Description>
                  <Paragraph ref={ref} onMouseEnter={handleHover} data-id={index}>
                    {el.description}
                  </Paragraph>
                </Description>
              </React.Fragment>
            )
          })}
        </Wrapper>
      </Container>
    </StyledOurValues>
  )
}

const Icon = styled.figure`
  max-width: 5rem;
  width: 5rem;
`

const ValueWrapper = styled.div`
  display: grid;
  gap: 3rem;
  grid-auto-flow: column;
  width: fit-content;
  align-items: center;
`

const StyledOurValues = styled.section`
  padding: 0 0 3rem;
  margin-top: -1px;
  position: relative;
  z-index: 1;
  :before {
    content: '';
    background-color: #fff;
    position: absolute;
    height: 100%;
    width: 200vw;
    left: -100vw;
    z-index: -1;
  }
  @media all and (max-width: 26.563em) {
    padding: 0;
  }
`

const Heading = styled.h4`
  font-size: 1.5rem;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  user-select: none;

  @media all and (max-width: 36.625rem) {
    font-size: 1.25rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }

  &&.active {
    color: ${({ theme }) => theme.colors.purple};
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  padding: 1.5rem 0;
  align-items: flex-start;
`

const Paragraph = styled.p`
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.grey700};
  padding-bottom: 3rem;
  max-width: 60ch;

  @media all and (max-width: 48em) {
    max-width: 50ch;
  }

  @media all and (max-width: 36.625em) {
    font-size: 0.875rem;
  }
`

const Value = styled.div`
  @media all and (max-width: 48em) {
    grid-column: -1/1;
  }
`

const Description = styled.div`
  @media all and (max-width: 48em) {
    grid-column: -1/1;
  }
`

export default OurValues
