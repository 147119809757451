import { ICompany } from '../typescript/interfaces';

export const pressReleases: ICompany[] = [
  {
    id: 'afr',
    alt: 'Australian Financial Review',
    url:
      'https://www.afr.com/companies/financial-services/commonwealth-bank-and-westpac-cautious-on-using-ai-for-compliance-20190306-h1c1ox/',
  },
  {
    id: 'smh',
    alt: 'Sydney Morning Herald',
    url:
      'https://www.smh.com.au/business/small-business/local-startups-crack-the-big-league-bemoan-dearth-of-fintech-talent-20191105-p537ha/',
  },
  {
    id: 'australian',
    alt: 'The Australian',
    url:
      'https://www.theaustralian.com.au/business/technology/daisee-sows-seeds-of-ai-in-business/news-story/97cb04079aab4bba9c3e4a9e21c69116/',
  },
  {
    id: 'itmunch',
    alt: 'iTMunch',
    url: 'https://itmunch.com/daisee-unity4-partner-ai-automated-quality-management-solution/',
  },
  {
    id: 'smart',
    alt: 'Smart Company',
    url:
      'https://www.smartcompany.com.au/startupsmart/news/ex-google-friendster-alumni-richard-kimber-raises-8-8-million-ai-startup-daisee/',
  },
  {
    id: 'it-news',
    alt: 'IT News',
    url: 'https://www.itnews.com.au/news/asic-plan-for-ai-snoops-on-insurance-calls-strains-hearing-531765/',
  },
  {
    id: 'yahoo',
    alt: 'Yahoo Finance',
    url: 'https://au.finance.yahoo.com/news/7-australian-companies-made-fintech-004617112.html',
  },
  {
    id: 'financial-times',
    alt: 'Financial Times',
    url: 'https://www.ft.com/content/33eb5934-4519-11e9-b168-96a37d002cd3/',
  },
  {
    id: 'fintech-singapore',
    alt: 'Fintech News Singapore',
    url: 'https://fintechnews.sg/36825/australia/9-hottest-australian-fintech-startups-for-2020/0/',
  },
  {
    id: 'speech-tech',
    alt: 'Speech Tech Mag',
    url:
      'https://www.speechtechmag.com/Articles/Editorial/Industry-Voices/The-AI-Driven-Future-of-Customer-Service-144364.aspx',
  },
  {
    id: 'arn-net',
    alt: 'ARNnet',
    url: 'https://www.arnnet.com.au/article/672264/deep-learning-power-ai-growth-apac/',
  },
  {
    id: 'voicebot',
    alt: 'Voicebot.ai',
    url:
      'https://voicebot.ai/2020/08/11/enterprise-ai-platform-veritone-integrates-speechmatics-speech-recognition-and-transcription-features/',
  },
  {
    id: 'mirage',
    alt: 'Mirage News',
    url:
      'https://www.miragenews.com/daisee-empowers-distributed-workforces-with-artificial-intelligence-to-do-great-business-despite/',
  },
  {
    id: 'nanalyze',
    alt: 'Nanalyze',
    url: 'https://www.nanalyze.com/2018/10/ai-startups-australia-new-zealand/',
  },
  {
    id: 'cxo',
    alt: 'CXO Today',
    url: 'https://www.cxotoday.com/news-analysis/deep-learning-shows-promising-growth-amid-challenges/',
  },
  {
    id: 'government-news',
    alt: 'Government News',
    url: 'https://www.governmentnews.com.au/government-should-lead-ai-certification-finkel/',
  },
  {
    id: 'it-brief',
    alt: 'IT Brief',
    url: 'https://itbrief.com.au/story/why-ai-will-play-crucial-role-climate-compliance/',
  },
  {
    id: 'acs',
    alt: 'ACS – Australian Computer Society',
    url: 'https://ia.acs.org.au/article/2018/aussie-ai-start-up-fresh-as-a-daisee.html/',
  },
  {
    id: 'hipaa',
    alt: 'HIPAA Journal',
    url: 'https://www.hipaajournal.com/daisee-confirmed-as-hipaa-compliant-by-compliancy-group/',
  },
  {
    id: 'anthill',
    alt: 'Anthill',
    url: 'http://anthillonline.com/microsoft-has-welcomed-14-australian-startups-to-its-debut-sydney-scaleup-program/',
  },
  {
    id: 'fin-smes',
    alt: 'FinSMEs',
    url: 'https://www.finsmes.com/2017/08/alium-capital-launches-100m-cross-over-venture-capital-fund.html',
  },
  {
    id: 'uc-today',
    alt: 'UC Today',
    url: 'https://www.uctoday.com/market-guide/cx-category/analytics/',
  },
  {
    id: 'pr-wire',
    alt: 'PR Wire',
    url: 'https://prwire.com.au/pr/91965/daisee-named-by-gartner-as-a-cool-vendor-in-ai-for-customer-analytics/',
  },
  {
    id: 'insurance-news',
    alt: 'Insurance News ',
    url: 'https://www.insurancenews.com.au/the-professional/daisee-perx-health-win-insurtech-competition/',
  },
  {
    id: 'startup-daily',
    alt: 'Startup Daily',
    url:
      'https://www.startupdaily.net/2018/05/richard-kimbers-journey-90s-banking-intrapreneur-cofounder-ai-startup-daisee/',
  },
  {
    id: 'daily-finance',
    alt: 'Daily Finance',
    url: 'https://df.media/these-are-the-top-insurtech-companies-in-australia-2021/',
  },
];
