export type AwardKeys =
  | 'gartner'
  | 'aseantech'
  | 'dxc'
  | 'fintech100'
  | 'fintech'
  | 'tech23'
  | 'techRocketship'
  | 'westpac'
  | 'australiaLanding'
  | 'anziif'
  | 'hkFintechWeek21'
  | 'regtech'

type Award = {
  id: AwardKeys
  label: string
  href: string
  alignSelf?: 'center' | 'start' | 'end'
}

export const awards: Award[] = [
  {
    id: 'gartner',
    label: 'daisee Gartner Cool Vendor 2020',
    href: '/blog/daisee-selected-by-gartner-as-a-cool-vendor-in-ai-for-customer-analytics/',
    alignSelf: 'center'
  },
  {
    id: 'aseantech',
    label: 'daisee ASEAN Tech Challenge 2018 Winner',
    href: 'https://innovationchallenges.global/challenge/37'
  },
  {
    id: 'dxc',
    label: 'daisee DXC Insurtech Winner',
    href:
      'https://www.dxc.technology/au/press_releases/146601-dxc_technology_announces_winners_for_insurtech_competition_for_startups'
  },
  {
    id: 'fintech100',
    label: 'daisee Fintech 100',
    href:
      'https://home.kpmg/au/en/home/media/press-releases/2019/11/australian-fintech-represented-in-fintech-100-5-november-2019.html'
  },
  {
    id: 'fintech',
    label: 'daisee 4th Annual Australian Fintech Startup Winner',
    href: 'https://australianfintech.com.au/the-4th-annual-australian-fintech-the-winners-and-photos/'
  },
  {
    id: 'tech23',
    label: 'daisee Tech23 Winner',
    href: 'https://tech23.com.au/2018/'
  },
  {
    id: 'techRocketship',
    label: 'daisee Tech Rocketship Winner',
    href: '/blog/daisee-wins-tech-rocketship-award-following-address-at-tech23/'
  },
  {
    id: 'westpac',
    label: 'daisee Westpac 200 Businesses of Tomorrrow',
    href: 'https://businessesoftomorrow.com.au/'
  },
  {
    id: 'australiaLanding',
    label: 'daisee Australia Landing Pad',
    href: 'https://www.austrade.gov.au/landingpads/locations/san-francisco/daisee'
  },
  {
    id: 'anziif',
    label: 'Australian Insurance Industry Awards',
    href: 'https://anziif.com/events/insurance-industry-awards/2021-finalists/'
  },
  {
    id: 'hkFintechWeek21',
    label: 'HK Fintech Week 2021 Global Fast Track Competition',
    href: 'https://www.fintechweek.hk/fast-track/'
  },
  {
    id: 'regtech',
    label: '2021 RegTech Award Finalists',
    href: 'https://regtech.org.au/page-18169/'
  }
]
