import { AnimatePresence, motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useState } from 'react'
import { ChevronDown } from 'react-feather'
import styled from 'styled-components'
import Container from '../Container'

const accordion = [
  {
    title: 'Security Culture',
    points: [
      'All employees must <b>complete regular</b> mandatory cyber security and awareness training.',
      'Our Cyber Security policies and code of conduct are constantly reviewed, and employees must adhere to them.',
      'Workstations are up to date with the latest security and os patches.',
      'Our customer and internal data is securely stored in the cloud.',
      '<b>All data is encrypted at rest on our servers, and all staff devices are fully encrypted.</b>',
      'We use MFA where possible',
    ],
  },
  {
    title: 'Platform Security',
    points: [
      'Customer data is backed up and encrypted',
      'Data in-transit is encrypted using TLS1.2.',
      'Data at rest is encrypted using AES256.',
      'Penetration tests are performed annually by an <b>independent</b> third-party.',
      'Our application lives in AWS, where we make use of AWS security services.',
    ],
  },
  {
    title: 'Access',
    points: [
      'Internal access is provided based on the <b>least-privilege principle.</b>',
      'MFA is enforced on all applications, where available.',
      'Password managers are used and complex passwords are mandatory.',
      'SAML 2.0 and OAuth 2 protocols are supported for SSO integration.',
    ],
  },
  {
    title: 'Compliance',
    points: [
      'daisee undergoes a SOC-2 Type II attestation on a yearly basis.',
      'Our infrastructure provider is SOC-2 compliant and ISO 27001 certified.',
      'Request access to our Security and SOC-2 reports <a target="_blank" style="font-weight:bold" href="https://security.daisee.com/">here</a>',
    ],
  },
]

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
}

const Security = () => {
  const [accordionState, setAccordionState] = useState<Record<string, boolean>>(
    accordion.reduce((acc, item) => ({ ...acc, [item.title]: false }), {}),
  )

  const images = useStaticQuery(graphql`
    query SecuritySection {
      hippa: file(relativePath: { eq: "marquee/certification/hipaa.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      soc2: file(relativePath: { eq: "soc2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <TimelineSection id='security'>
      <Recognition>
        <Container>
          <SectionHeading>Security</SectionHeading>
          <H3>Security is part of daisee's culture</H3>
          <Grid>
            <Copy>
              We understand that by working with us, you and your customers, are trusting us with a variety of private
              information. We strive to go above and beyond to make sure that your data is kept secure.
            </Copy>
          </Grid>
        </Container>
        <Container>
          {accordion.map((item, index) => {
            return (
              <div key={item.title}>
                <AccordionHead
                  onClick={() =>
                    setAccordionState(prevState => ({ ...prevState, [item.title]: !prevState[item.title] }))
                  }
                  showBorderBottom={accordionState[item.title] || index === accordion.length - 1}
                >
                  <h3>{item.title}</h3>
                  <ChevronWrapper isOpen={accordionState[item.title]}>
                    <ChevronDown />
                  </ChevronWrapper>
                </AccordionHead>
                <AnimatePresence initial={false}>
                  {accordionState[item.title] && (
                    <AccordionBody
                      key='content'
                      initial='collapsed'
                      animate='open'
                      exit='collapsed'
                      variants={variants}
                      transition={{ duration: 0.5, ease: [0.7, -0.005, 0, 1.005] }}
                    >
                      <ul style={{ padding: '1.5rem 0.5rem', marginLeft: '1rem', listStyle: 'disc' }}>
                        {item.points.map(point => {
                          return (
                            <li key={point}>
                              <p dangerouslySetInnerHTML={{ __html: point }} />
                            </li>
                          )
                        })}
                      </ul>
                    </AccordionBody>
                  )}
                </AnimatePresence>
              </div>
            )
          })}
          <p
            style={{
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              padding: '5rem 0 0',
              color: '#ACB5BD',
            }}
          >
            Compliance certifications and attestations
          </p>
          <BadgeWrapper>
            <CertificationWrapper>
              <Img style={{ width: 100, marginBottom: '0.75rem' }} fluid={images?.soc2?.childImageSharp?.fluid} />
              <div style={{ marginTop: '2.25rem' }}>
                <p style={{ fontWeight: 500 }}>SOC 2</p>
                <a target={'_blank'} href='https://security.daisee.com/'>
                  Request report
                </a>
              </div>
            </CertificationWrapper>
          </BadgeWrapper>
        </Container>
      </Recognition>
    </TimelineSection>
  )
}

const CertificationWrapper = styled.div`
  display: grid;

  ${({ theme }) => theme.breakpoints.mobile} {
    justify-items: center;
  }

  a {
    color: ${({ theme }) => theme.colors.purple};
    text-decoration: underline;
  }
`

const BadgeWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 4rem;
  padding: 2rem 0;

  ${({ theme }) => theme.breakpoints.mobile} {
    display: flex;
    flex-direction: column;
  }
`

const ChevronWrapper = styled.div<{ isOpen: boolean }>`
  margin-left: auto;
  svg {
    transition: transform 0.3s ease;
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  }
`

const AccordionBody = styled(motion.div)`
  overflow: hidden;

  li {
    font-size: 0.875rem;
    list-style: disc;
  }

  b {
    color: ${({ theme }) => theme.colors.purple};
  }

  a {
    color: ${({ theme }) => theme.colors.purple};
    text-decoration: underline;
  }
`

const AccordionHead = styled.div<{ showBorderBottom: boolean }>`
  border-top: ${({ theme }) => `1px solid ${theme.colors.grey300}`};
  border-bottom: ${({ showBorderBottom, theme }) => (showBorderBottom ? `1px solid ${theme.colors.grey300}` : 'none')};
  padding: 1rem 0.5rem;
  display: flex;
  cursor: pointer;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #f5f7f9;
  }
`

const TimelineSection = styled.section``

const SectionHeading = styled.h2`
  font-size: 4rem;
  font-weight: 300;
  letter-spacing: -2px;
  color: ${({ theme }) => theme.colors.grey400};
  padding: 0 0 6rem;

  @media all and (max-width: 48em) {
    font-size: 3rem;
    padding: 0 0 3rem;
  }

  @media all and (max-width: 28.125em) {
    font-size: 2.5rem;
  }
`

const Recognition = styled.article`
  padding: 3rem 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 0 6rem;
  gap: 1.5rem;
  @media all and (max-width: 39.375em) {
    grid-template-columns: 1fr;
  }
`

const Copy = styled.p`
  color: ${({ theme }) => theme.colors.grey700};
  line-height: 1.7;
  font-weight: 500;
`

const H3 = styled.h3`
  padding: 0rem 0 5rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -1px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -1.5rem;
    width: 4rem;
    height: 4px;
    background: ${({ theme }) => theme.colors.purple};
  }

  @media all and (max-width: 48em) {
    line-height: 1.3;
  }

  @media all and (max-width: 39.375em) {
    font-size: 1.5rem;
  }
`

export default Security
