import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { uid } from 'react-uid'
import { Controller, Scene } from 'react-scrollmagic'
import { debounce } from 'lodash'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import { InView } from 'react-intersection-observer'
import Container from '../Container'
import { SectionHeading } from '../OurPeople'
import TimelineSvg from '../../assets/timeline.svg'
import getGradient from '../utils/getGradient'
import { timeline } from '../../constants/timeline'

export default () => {
  const [year, setYear] = useState<number>(2017)
  const [trackHeight, setTrackHeight] = useState<number>(0)
  const ref = useRef<HTMLDivElement>(null)
  const data = useStaticQuery(graphql`
    query Team {
      ui: file(relativePath: { eq: "timeline/ui_closeup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      microsoft: file(relativePath: { eq: "timeline/microsoft_daisee.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      richard: file(relativePath: { eq: "timeline/richard_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      customerOne: file(relativePath: { eq: "timeline/customerOne.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scriptAdherence: file(relativePath: { eq: "timeline/scriptAdherence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hipaa: file(relativePath: { eq: "timeline/hipaa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gartner: file(relativePath: { eq: "timeline/gartner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      genesys: file(relativePath: { eq: "timeline/genesys.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      stephen: file(relativePath: { eq: "timeline/stephen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kylie: file(relativePath: { eq: "timeline/kylie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      workflow: file(relativePath: { eq: "timeline/workflow.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      david: file(relativePath: { eq: "timeline/david.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      agentView: file(relativePath: { eq: "timeline/timeline-agentView.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      teamView: file(relativePath: { eq: "timeline/timeline-teamView.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      essence: file(relativePath: { eq: "timeline/timeline-essence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carlos: file(relativePath: { eq: "timeline/carlos.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      soc2: file(relativePath: { eq: "timeline/soc2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gdpr: file(relativePath: { eq: "timeline/gdpr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      omnichannelChat: file(relativePath: { eq: "timeline/daiseeChat.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      micha: file(relativePath: { eq: "timeline/micha.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kyle: file(relativePath: { eq: "timeline/kyle.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const handleResize = () => {
    if (ref.current) {
      setTrackHeight(ref.current.getBoundingClientRect().height)
    }
  }

  useEffect(() => {
    if (ref.current) {
      setTrackHeight(ref.current.getBoundingClientRect().height)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize, 300))

    return () => window.removeEventListener('resize', debounce(handleResize, 300))
  })

  return (
    <Container>
      <TimelineSection id='our-story'>
        <SectionHeadingWrapper>
          <SectionHeading>Our Story</SectionHeading>
        </SectionHeadingWrapper>
        <TimelineWrapper ref={ref}>
          <Controller>
            <Scene duration={trackHeight} pin={{ pushFollowers: false }}>
              <Marker>{year}</Marker>
            </Scene>
            <Article>
              {timeline.map((event, index) => (
                <InView
                  as='div'
                  rootMargin='0px 0px -48.5% 0px'
                  onChange={(inView: boolean) => inView && setYear(event.year)}
                  key={uid(event)}
                >
                  {event.link ? (
                    <Row data-year={event.year}>
                      <RowInner markerYOffset={index % 2 === 0}>
                        <Month>{event.month}</Month>
                        <Description>{event.description}</Description>
                        <Copy>{event.copy}</Copy>
                        {event.link.includes('http') ? (
                          <a href={event.link} target='_blank' rel='noreferrer'>
                            {data[event.id] && (
                              <EventImg imgStyle={event?.imgStyle} fluid={data[event.id].childImageSharp.fluid} />
                            )}
                          </a>
                        ) : (
                          <Link to={event.link}>
                            {data[event.id] && (
                              <EventImg imgStyle={event?.imgStyle} fluid={data[event.id].childImageSharp.fluid} />
                            )}
                          </Link>
                        )}
                      </RowInner>
                    </Row>
                  ) : (
                    <Row data-year={event.year}>
                      <RowInner markerYOffset={index % 2 === 0}>
                        <Month>{event.month}</Month>
                        <Description>{event.description}</Description>
                        <Copy>{event.copy}</Copy>
                        {data[event.id] && (
                          <EventImg imgStyle={event?.imgStyle} fluid={data[event.id].childImageSharp.fluid} />
                        )}
                      </RowInner>
                    </Row>
                  )}
                </InView>
              ))}
            </Article>
          </Controller>
        </TimelineWrapper>
      </TimelineSection>
    </Container>
  )
}

const TimelineSection = styled.section`
  padding-top: 9rem;
`

interface ImgProps {
  fluid: {}
}

const EventImg = styled(Img) <ImgProps>`
  transition: opacity 0.5s ease, box-shadow 0.5s ease;
  object-fit: cover;
  width: 100%;
  height: 250px;
  background-position: center bottom;
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  @media all and (max-width: 39.375em) {
    display: none;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadow.medium};
  }
`

const Article = styled.article`
  @media all and (max-width: 39.375em) {
    padding-left: 2rem;
  }
`

const TimelineWrapper = styled.div`
  position: relative;
  height: auto;

  &:before {
    content: '';
    position: absolute;
    background-image: url(${TimelineSvg});
    background-repeat: repeat-y;
    background-size: 2px 12px;
    width: 2px;
    left: 50%;
    height: 100%;

    @media all and (max-width: 39.375em) {
      left: 2rem;
    }
  }
`

const Marker = styled.div`
  background: ${({ theme }) => getGradient(theme.colors.purple)};
  padding-top: ${({ theme }) => theme.spacing.xxs};
  padding-bottom: ${({ theme }) => theme.spacing.xxs};
  padding-left: ${({ theme }) => theme.spacing.md};
  padding-right: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: #fff;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  width: 5rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
  user-select: none;
  z-index: 2;
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 39.375em) {
    && {
      margin-left: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media all and (max-width: 28.125em) {
    && {
      width: auto;
      font-size: 0.875rem;
    }
  }
`

const Row = styled.div`
  margin-bottom: 6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media all and (max-width: 39.375em) {
    grid-template-columns: 1fr;
  }
`

interface RowProps {
  markerYOffset: boolean
}

const RowInner = styled.div<RowProps>`
  position: relative;
  grid-column: ${({ markerYOffset }) => markerYOffset && '2/2'};
  padding-left: 7rem;
  padding-right: 3rem;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: ${({ markerYOffset }) => markerYOffset && '-3px'};
    right: ${({ markerYOffset }) => !markerYOffset && '-5px'};
    background: ${({ theme }) => theme.colors.purple};

    @media all and (max-width: 39.375em) {
      right: auto;
      left: -3px;
    }
  }

  @media all and (max-width: 48em) {
    padding-left: 3rem;
  }

  @media all and (max-width: 39.375em) {
    grid-column: 1/1;
    padding-left: 5rem;
  }

  @media all and (max-width: 28.125em) {
    padding-left: 3rem;
  }
`

const Month = styled.p`
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey500};
  padding-bottom: 0.75rem;
  line-height: 1;
`

const Description = styled.h4`
  font-size: 1.25rem;
  font-weight: 500;
  padding-bottom: 1.25rem;

  @media all and (max-width: 28.125em) {
    font-size: 1rem;
  }
`

const Copy = styled.p`
  color: ${({ theme }) => theme.colors.grey700};
  font-size: 0.875rem;
  padding-bottom: 1.5rem;

  @media all and (max-width: 28.125em) {
    font-size: 0.75rem;
  }
`

const SectionHeadingWrapper = styled.div`
  padding: 0 1.5rem;

  @media all and (max-width: 39.375em) {
    padding: 0 0 3rem;
  }
`
