import { IPressReleaseData, ICompany } from '../typescript/interfaces';

interface Props {
  list: ICompany[];
  data: IPressReleaseData;
}

/**
 *
 * @param list - The ordered list in which elements are to be displayed
 * @param name - Image file `name` that matches a corresponding `id` in the ordered list
 */
export const orderImages = ({ list, data }: Props) =>
  list.map((el: ICompany) => {
    const obj = data.allFile.edges.find(({ node }) => node.name === el.id);

    if (obj?.node) {
      const imgSrc = obj.node?.childImageSharp?.fluid ?? obj.node.publicURL;
      return {
        ...el,
        imgSrc,
      };
    }
  });
