import { SeoObject } from '../components/Site'

type Page =
  | 'home'
  | 'about'
  | 'ai'
  | 'blog'
  | 'careers'
  | 'contact'
  | 'customers'
  | 'privacy'
  | 'product'
  | 'solutions'
  | 'startDemo'
  | 'voiceAnalytics'
  | 'fourOhFour'
  | 'whitepaper'
  | 'drClaire'
  | 'explainer'
  | 'news'
  | 'newCeo'
  | 'thankyou'
  | 'ebook'
  | 'usingAi'

const defaultKeywords = [
  'contact centre software',
  'AI speech analytics software',
  'call centre voice analytics',
  'contact centre QA software',
  'ai customer interactions',
  'ai customer experience',
  'AI CX software'
]

export const SEO: Record<Page, SeoObject> = {
  home: {
    title: 'daisee | AI voice analytics and contact centre quality assurance software',
    description:
      'Enhance CX, evaluate agent performance, rapdily transcribe 100% of calls and improve quality assurance standards',
    keywords: [
      'call center qa',
      'automated quality management',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  about: {
    title: 'daisee | AI software company pioneering speech-to-text and voice analytics',
    description:
      'daisee is an Australian AI software company pioneering speech-to-text and voice analytics technology.',
    keywords: [
      'call center qa',
      'automated quality management',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  ai: {
    title: 'daisee | Essence and The future of AI',
    description:
      'daisee Essence is a powerful new module that provides businesses and teams with Augmented and Artificial Intelligence that hears and detects the true reason behind each customer’s voice interaction. Unlike other platforms, Essence actively analyses and recognises the meaningful phrases used on each call to establish customer intent, signals and trends.',
    keywords: [
      'call center qa',
      'automated quality management',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  blog: {
    title: 'daisee | In The Newsroom',
    description:
      'Explore the daisee blog to learn more about our advancements in the field of AI, recent partnerships and our product developments',
    keywords: [
      'call center qa',
      'automated quality management',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  careers: {
    title: 'daisee | Careers and openings at daisee',
    description:
      'Discover a career at daisee and help us continue to be the global leader in AI-driven speech anlaytics',
    keywords: [
      'software engineering sydney',
      'typescript',
      'haskell',
      'customer engineer sydney',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  contact: {
    title: 'daisee | Contact us',
    description: 'Contact daisee for technical support and enquiries through the form provided',
    keywords: [
      'call center qa',
      'automated quality management',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  customers: {
    title: 'daisee | Customer success stories',
    description: 'Hear first hand about the success from our customers after integrating daisee',
    keywords: [
      'call center qa',
      'automated quality management',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  privacy: {
    title: 'daisee | Privacy Policy',
    description:
      "Privacy Policy. Read about how we collect and handle personal information about you when you use visit daisee's website"
  },
  product: {
    title: 'daisee | AI speech-to-text and voice analytics software',
    description:
      'Our product enables you to understand what your customers are saying and provides critical insights into your customers’ voice, surfacing opportunities, obstacles and risks like never before.',
    keywords: [
      'call center qa',
      'automated quality management',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  solutions: {
    title: 'daisee | AI speech-to-text and voice analytics software solutions',
    description:
      'daisee leverages AI, machine learning, and analytics to reveal actionable insights for your business. Discover a solution today here.',
    keywords: [
      'call center qa',
      'automated quality management',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions',
      'speech analytics machine learning'
    ]
  },
  startDemo: {
    title: 'daisee | Start a demo',
    description: 'Discover the power of daisee and start a demo today',
    keywords: [
      'call center qa',
      'automated quality management',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call center quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  voiceAnalytics: {
    title: 'daisee | AI Voice Analytics Software',
    description:
      'Enhance CX, evaluate agent performance, rapdily transcribe 100% of calls and improve quality assurance standards.',
    keywords: [
      'voice analytics',
      'automated quality management',
      'automated quality assurance',
      'contact centre qa',
      'ai speech analytics',
      'script adherence technology',
      'call centre quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  fourOhFour: {
    title: '404 - Not Found',
    description: '404 not found'
  },
  whitepaper: {
    title: 'daisee | White Paper',
    description:
      'Getting past "compensation effects": The importance of monitoring and managing claimant distress - Dr Claire Ashton-James, PhD',
    keywords: [
      'claimant distress',
      'distress',
      'compensation',
      'claims process',
      'ai speech analytics',
      'script adherence technology',
      'call centre quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  ebook: {
    title: 'daisee | E-book',
    description: 'Harnesses AI To Solve Contact Centre Problems',
    keywords: [...defaultKeywords]
  },
  drClaire: {
    title: 'daisee | Events | Insurance Roundtable',
    description: 'Leveraging AI to optimise claimant outcomes, with social psychologist, Dr Claire Ashton-James',
    keywords: [
      'claimant distress',
      'insurance claimant',
      'compensation',
      'claims process',
      'ai speech analytics',
      'script adherence technology',
      'call centre quality management system',
      'ai quality assurance',
      'ai customer interactions'
    ]
  },
  explainer: {
    title: 'daisee | Voice analytics platform powered by AI and machine learning',
    description:
      'daisee empowers contact centers by leveraging AI to enhance, analyse and evaluate 100% of customer conversations.',
    keywords: [...defaultKeywords]
  },
  news: {
    title: 'daisee | News and announcements',
    description: 'Stay up to date with the latest news and announcements from daisee and our partners',
    keywords: [...defaultKeywords]
  },
  newCeo: {
    title: 'daisee | Announces New CEO and Bolstering of Management Team',
    description:
      'daisee, an Australian A.I. software company named as a recent Gartner Cool Vendor, announced today the appointment of Dr Kylie de Boer as CEO.',
    keywords: [...defaultKeywords, 'Richard Kimber']
  },
  thankyou: {
    title: 'daisee | Thankyou',
    description:
      'Book a time to speak with a daisee Client Success Manager to learn how our AI technology can empower your contact centre business',
    keywords: [...defaultKeywords, 'e-book']
  },
  usingAi: {
    title: 'daisee | Whitepaper',
    description: 'Using AI to extract insights from customer conversations in financial services',
    keywords: [...defaultKeywords, 'whitepaper']
  }
}
