import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Container from '../Container'
import styled, { useTheme } from 'styled-components'
import { Name, Person, Role, SectionHeading, StyledImg } from '../OurPeople'
import Rule from '../Rule'

const board = [
  { id: 'richard', name: 'Richard Kimber', role: 'Founder & Director', link: '/our-people/richard-kimber' },
  { id: 'rajeev', name: 'Rajeev Gupta', role: 'Non-executive', link: '/our-people/rajeev-gupta' }
]

const Board = () => {
  const theme = useTheme()
  const images = useStaticQuery(graphql`
    query Board {
      tim: file(relativePath: { eq: "board/tim.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      richard: file(relativePath: { eq: "board/richard.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      rajeev: file(relativePath: { eq: "board/rajeev.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Container id='our-board'>
      <SectionHeading>Our Board</SectionHeading>
      <Rule />

      <Grid>
        {board.map((member, i) => {
          const { id, name, link, role } = member
          const image = images[id]
          return (
            <Link to={link} key={id}>
              <Person index={i}>
                <StyledImg
                  imgStyle={{
                    objectFit: 'cover',
                    borderRadius: '50%'
                  }}
                  style={{
                    objectPosition: '50% 50%',
                    borderRadius: '50%',
                    boxShadow: theme.boxShadow.large,
                    overflow: 'visible'
                  }}
                  fluid={image.childImageSharp.fluid}
                  alt={name}
                />
                <Name>{name}</Name>
                <Role>{role}</Role>
              </Person>
            </Link>
          )
        })}
      </Grid>
    </Container>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 3rem 0 6rem;

  a:last-child:nth-child(3n - 2) {
    grid-column-start: 2;
  }

  @media all and (max-width: 48em) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    a:last-child:nth-child(3n - 2) {
      grid-column-start: 1;
    }
  }

  @media all and (max-width: 36.625em) {
    gap: 4rem 1rem;
  }

  @media all and (max-width: 26.563em) {
    gap: 3rem 1rem;
    justify-content: end;
  }

  gap: 3rem;
`

export default Board
