import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useVideo } from 'react-use'

const defaultProps = {
  preload: 'metadata',
  autoPlay: false,
  controls: true,
  playsInline: true,
  src: 'https://d1mh6ff1r8xw52.cloudfront.net/people.mp4'
}

export const useDaiseePeopleVideo = (props?: any) => {
  const people = useStaticQuery(graphql`
  query peopleAndPeople {
    file(relativePath: { eq: "people-of-daisee-thumbnail.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100, maxHeight: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`)

  const [video, state, controls] = useVideo(
    <video
      poster={people.file.childImageSharp.fluid.src}
      {...defaultProps}
      {...props}
    />
  )

  return { video, state, controls }
}